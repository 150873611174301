import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { usePathname } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { AuthContext } from 'src/auth/context/jwt';
import customAxios from 'src/utils/customAxios';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import InitialDialog from './component/initial-dialog';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const pathname = usePathname();

  const isAIChat = pathname === '/dashboard/ai-chat';

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const { user } = useAuthContext(AuthContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(true);
  const [userData, setUserData] = useState(null);

  const getUser = async () => {
    try {
      if (user?.userID) {
        const {
          data: { data },
        } = await customAxios.get(`https://abibiman-api.onrender.com/users/${user?.userID}`);

        setUserData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userID]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      userData?.onBoarding === 'personal' &&
      userData?.adminOnBoarding === 'pending' &&
      showDialog
    ) {
      const interval = setInterval(() => {
        setDialogOpen(true);
      }, 6000);

      return () => clearInterval(interval);
    }
  }, [userData, showDialog]);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>
          <Box sx={{ height: '40px', width: '100%', display: isAIChat && 'none' }} />
          {children}

          <Dialog open={dialogOpen}>
            <InitialDialog setDialogOpen={setDialogOpen} setShowDialog={setShowDialog} />
          </Dialog>
        </Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
